<template>
  <b-overlay
    :show="store_check_form.overlayState"
    rounded="lg"
    variant="dark"
    :opacity="0.75"
  >
    <template #overlay>
      <b-icon
        icon="stopwatch"
        font-scale="5"
        animation="throb"
        variant="warning"
      />
      <p animation="cylon">Un instant...</p>
    </template>
    <slot></slot>
  </b-overlay>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['store_check_form']),
  },
}
</script>
